<template>
  <div class="wrap">
    <div class="nav">
      <div v-if="state == 0">积分兑换</div>
      <div v-if="state == 1">积分兑换记录</div>
      <div @click="state = 1" v-if="state == 0" class="fr">兑换记录</div>
    </div>
    <div class="list" v-if="state == 0">
      <div class="top">
        <p class="one">商品名称</p>
        <p class="one">所需积分</p>
        <p class="one">操作</p>
      </div>
      <div v-for="(v, i) in scoreShopList" :key="i">
        <p class="one">{{ v.goods_name }}</p>
        <p class="one">{{ v.score }}</p>
        <p class="one fll" @click="exchangeGood(v)">兑换</p>
      </div>
    </div>
    <div class="exchangeDetails">
      <div v-if="state == 1" class="exchange">
        <div class="list list2" v-for="(v,i) in recordList" :key="i">
          <div class="top">
            <p class="one">{{ v.ordertime }}</p>
            <p class="one">数量</p>
            <p class="one">状态</p>
            <p class="one">积分</p>
          </div>
          <div>
            <p class="one">{{ v.goods_name }}</p>
            <p class="one">{{ 1 }}</p>
            <p class="one"> {{ v.status == 1 ? "已兑换" : "待兑换" }}</p>
            <p class="one"> {{ v.score }} </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { buyScore } from "@/request/user"; // 兑换商品
import { score_shop } from "@/request/user"; // 可兑换商品列表
import { recordOrder } from "@/request/user"; // 个人兑换记录
export default {
  data() {
    return {
      state: 0,
      scoreShopList: [],
      recordList: []
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    this.shop_id = localStorage.getItem("lzyyShopId");
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.start();
  },
  methods: {
    start() {
      score_shop({ token: this.$token }).then(res => {
        this.scoreShopList = res.data.data;
      });
      recordOrder({ token: this.$token }).then(res => {
        this.recordList = res.data.data;
        // if(this.recordList.length == 0) {
        //   this.$message({
        //     type: "error",
        //     message: "未有兑换商品记录！"
        //   })
        // }
      });
    },
    exchangeGood(v) {
      buyScore({
        token: this.$token,
        shop_id: this.shop_id,
        id: v.id
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "兑换成功"
          });
        } else {
          this.$message.error(res.msg)
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.list {
  min-height: 400px;
  padding-bottom: 60px;
  padding-left: 29px;
  padding-right: 29px;
  padding-top: 29px;
  > div {
    height: 69px;
    font-size: 16px;
    color: #4d4d4d;
    display: flex;
  }
  .top {
    background: #f8f8f8;
  }
  .top p {
    border-top: 1px solid #dbdbdb;
  }
  p {
    border-right: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    height: 69px;
    line-height: 69px;
    width: 40%;
    text-align: center;
  }

  div > p:nth-of-type(1) {
    width: 586px;
    text-indent: 10px;
    border-left: 1px solid #dbdbdb;
  }
  div > p:nth-of-type(2) {
    width: 364px;
    border-left: 1px solid #dbdbdb;
  }
  div > p:nth-of-type(3) {
    width: 194px;
    border-left: 1px solid #dbdbdb;
  }
  .fll {
    color: #4eeec8;
    cursor: Pointer;
  }
}
.list2 {
  min-height: 180px;
  padding-bottom: 0;
  > div {
    border: 1px solid #e6e6e6;
  }
  .top {
    border-bottom: none;
  }
  p {
    border: none !important;
  }
  div > p:nth-of-type(1) {
    width: 40%;
    text-indent: 10px;
  }
  div > p:nth-of-type(2) {
    width: 20%;
  }
  div > p:nth-of-type(3) {
    width: 20%;
  }
  div > p:nth-of-type(4) {
    width: 20%;
  }
}
.exchangeDetails {
  padding-bottom: 100px;
  .exchange {
    min-height: 500px;
  }
}
.nav {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  padding-left: 32px;
  border-bottom: 1px solid #e6e6e6;
  div {
    padding-left: 16px;
    padding-right: 16px;
    // color: #4EEEC8;
    display: inline-block;
    // border-bottom: 2px solid #4EEEC8;
  }
  .fr {
    margin-right: 32px;
    color: #4eeec8;
    cursor: Pointer;
  }
}
</style>