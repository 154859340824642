<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw integralBox" v-if="state != 2">
          <div class="nav">
            <div>
              <span>{{ score }}</span>
              <span style="color:#1A1A1A;font-size:18px;">可用积分</span>
            </div>
            <div @click="changeDuihuan">积分兑换</div>
            <div @click="state = 2">抽奖积分</div>
            <div @click="relesType = 1">积分规则说明</div>
          </div>
          <!-- 首页 积分列表 -->
          <integralList v-if="state == 0"></integralList>
          <!-- 积分兑换 -->
          <exchange v-if="state == 1"></exchange>
          <!-- 积分规则说明 -->
          <div v-if="relesType == 1" @click="tab()" class="atricle" @mousewheel.prevent>
            <div @click.stop class="txtbox bgw">
              <h1>积分规则说明</h1>
              <div class="txt">
                <p v-for="(v, i) in integral_rule" :key="i">
                  {{ i + 1 }}. {{ v }} 
                </p>
              </div>
              <button @click="tab()">确定</button>
            </div>
          </div>
        </div>
        <!-- 积分抽奖 -->
		<div class="cj">
			  <draw  v-if="state == 2"></draw>
		</div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "../header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部

import integralList from "./integralList"; //首页 积分列表
import exchange from "./exchange"; // 兑换
import draw from "./draw"; //抽奖

import { usergetinfo } from "@/request/user";
import { getUserScore } from "@/request/index";
import { getSysconfs } from "@/request/public";
export default {
  components: {
    headers,
    publicBottom,
    integralList,
    exchange,
    draw
  },
  data() {
    return {
      userList: [], // 个人信息
      state: 0,
      relesType: 0,
      score: 0,
      sysconfs: {},
      integral_rule: []
    };
  },
  created() {
    if (this.$route.query.state) {
      this.state = +this.$route.query.state;
    }
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.start();
    // this.state=this.$route.query.state
  },
  methods: {
    tab() {
      this.relesType = 0;
    },
    start() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
      // 用户积分
      getUserScore({
        token: this.$token
      }).then(res => {
        this.score = res.data.score;
      });
      // 积分规则信息
      getSysconfs({
        token: this.$token
      }).then(res => {
        if (res.code == 1) {
          this.sysconfs = res.data;
          this.integral_rule = this.sysconfs.integral_rule.split(/\d\./g);
          this.integral_rule = this.integral_rule.filter(item => item != '')
        }
      });
    },
    changeDuihuan() {
      this.state = 10;
      this.$nextTick(() => (this.state = 1));
    }
  }
};
</script>

<style lang="less" scoped>
	.cj{
		position: relative;
	}
.atricle .txtbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 828px;
  height: 562px;
  padding-top: 30px;
  .txt {
    color: #4d4d4d;
    font-size: 18px;
    height: 388px;
    padding-left: 48px;
    padding-right: 48px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 40px;
  }
  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 36px;
  }
  button {
    width: 108px;
    height: 46px;
    font-size: 18px;
    display: block;
    margin: auto;
    margin-top: 10px;
  }
}
.atricle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
}
.integralBox {
  border: 1px solid #e6e6e6;

}
.nav {
  height: 128px;
  display: flex;
  padding-top: 41px;
  border-bottom: 1px solid #e6e6e6;
  > div {
    height: 46px;
    line-height: 46px;
    width: 25%;
    font-size: 18px;
    color: #4eeec8;
    text-align: center;
    border-left: 1px solid #f0f0f0;
    cursor: Pointer;
  }
  div:nth-of-type(1) {
    position: relative;
    border: none;
    font-size: 36px;
  }
  span {
    position: absolute;
    display: inline-block;
    width: 40%;
    height: 46px;
  }
  span:nth-of-type(1) {
    width: 50%;
    text-align: right;
    left: 10px;
  }
  span:nth-of-type(2) {
    text-align: left;
    right: 0;
  }
}
</style>