<template>
	<div class="content">
		<div class="wrap">
			<div class="box">
				<div class="fl">
					<div>
						<img src="@/assets/image/Luckdraw_Title_img@2x.png" alt />
					</div>
					<div class="list">
						<div class="record">
							<div>中奖记录</div>
							<div class="record_content">
								<div class="none" v-if="prizeRecord.length == 0">暂无中奖记录 ！</div>
								<div v-else>
									<p v-for="(v, i) in currentPrizeRecord" :key="i">
										<span class="fl">{{ v.goods_name }}</span>
										<span class="fr">{{ v.ordertime }}</span>
									</p>
									<div class="list">
										<span @click="changePage('prev')" class="l">&lt;</span>
										<span @click="changePage(v)" v-for="(v, i) in parseInt(prizeRecord.length / 5)" :key="i" :class="{active: defalutPage == v}">{{ v }}</span>
										<span @click="changePage('next')" class="l">&gt;</span>
									</div>
								</div>
							</div>
						</div>
						<div class="rule">
							<div>活动规则:</div>
							<p v-for="(v, i) in rules" :key="i">{{ i + 1 }}. {{ v }}</p>
						</div>
					</div>
				</div>
				<div class="fr">
					<!-- <img src="@/assets/image/caidai.png" class="caidai" /> -->
					<div class="conbox">
						<div class="container">
							<div class="main">
								<div class="canvas-container">
									<div :animation="animationData" class="canvas-content" id="zhuanpano">
										<!-- <div :animation="animationData" class="canvas-content" id="zhuanpano"  :style="[{transform:'rotate('+runDeg+')'}]"></div> -->
										<!-- <canvas class="canvas" canvas-id="canvas"></canvas> -->
										<div class="canvas-line">
											<div class="canvas-litem" v-for="(item,index1) in awardsList" :key="index1" :style="[{transform:'rotate('+item.lineTurn+')'}]"></div>
										</div>
										<div class="canvas-list">
											<div class="canvas-item" :style="[{zIndex:index2}]" v-for="(iteml,index2) in awardsList" :key="index2">
												<div class="canvas-item-text" :style="[{transform:'rotate('+iteml.turn+')'}]">
													<span>{{iteml.award}}</span>
													<img :src="iteml.goods_img" class="canvas-item-text-img" />

													<img class="canvas-item-text-img" src="@/assets/image/xiaolian.png" v-if="iteml.type == 3" />
												</div>
											</div>
										</div>
									</div>
									<div @click="playStart" class="canvas-btn" v-bind:class="btnDisabled">开始</div>
								</div>
							</div>
						</div>
					</div>
					<img class="balloon" src="@/assets/image/Luckdraw_decorate1@2x.png" alt />
					<img class="five_star" src="@/assets/image/Luckdraw_decorate2@2x.png" alt />
					<img class="shadow" src="@/assets/image/Luckdraw_shadow@2x.png" alt />
				</div>
			</div>
		</div>
		<div class="addressbox" v-show="choiceAddress" @click="choiceAddress=false">
			<div class="addresslist">
				<p class="title">选择收货地址</p>
				<div class="attribute">
					<div>地址</div>
					<div>用户名</div>
				</div>
				<div class="addresscontent">
					<div class="alist" v-for="(i,index) in addressList" :key="index" @click="clickalist(i.id)">
						<span>{{i.city_name}}-{{i.area_name}}-{{i.address}}</span><span>{{i.user_name}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getSysconfs
	} from "@/request/public";
	import {
		ringList
	} from "@/request/user"; // 奖品列表
	import {
		getMyRing
	} from "@/request/user"; // 中奖记录
	import {
		ringStart
	} from "@/request/user"; // 开始抽奖
	import {
		user_address_List
	} from "@/request/user";
	export default {
		data() {
			return {
				awardsConfig: {
					lists: [] //奖品列表
				},
				awardsList: {},
				animationData: {},
				btnDisabled: "", // 是否可以抽奖
				chishu: 3,
				r_flg: 0,
				myPrizelist: [],
				sysconfs: {},
				rules: [],
				prizeRecord: [], // 中奖记录
				currentPrizeRecord: [], // 当前展示的中奖记录 5条数据
				last_page: 1,
				defalutPage: 1,
				address_id: "",
				addressList: "",
				choiceAddress: false,
			};
		},
		created() {
			this.shop_id = localStorage.getItem("lzyyShopId");
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.activityRule();
			this.getPrizeRecord(1);
			this.getUserAddressList();
		},
		mounted() {
			this.getRingList();
		},
		methods: {
			clickalist(id) {
				this.address_id = id;
				this.choiceAddress = false;
			},
			// 获取地址列表
			getUserAddressList() {
				user_address_List({
					token: this.$token
				}).then(res => {
				this.addressList = res.data.data;
					console.log(this.addresList);
				});
			},
			getRingList() {
				ringList({
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.initdata(this, res.data.datas);
					}
				});
			},
			initdata: function(that, dataList) {
				let data = {
					lists: dataList
				};

				that.awardsConfig = data;
				// 获取奖品的个数
				let awarrlength = that.awardsConfig.lists.length;
				// push 谢谢参与奖项
				that.awardsConfig.lists.splice(awarrlength, 0, {
					name: "谢谢参与",
					goods_img: require("@/assets/image/xiaolian.png")
				});
				// 为每一项追加index属性
				that.awardsConfig.lists.forEach(function(element, index) {
					element.index = index;
				});
				// 画转盘
				that.drawAwardRoundel();
			},
			//画抽奖圆盘
			drawAwardRoundel: function() {
				// 拿到奖品列表
				var awards = this.awardsConfig.lists;
				console.log(awards);
				var awardsList = [];
				// 每份奖品所占角度
				var turnNum = 360 / awards.length; // 文字旋转 turn 值
				// 奖项列表
				for (var i = 0; i < awards.length; i++) {
					awardsList.push({
						turn: i * turnNum + "deg", //每个奖品块旋转的角度
						lineTurn: i * turnNum + turnNum / 2 + "deg", //奖品分割线的旋转角度
						award: awards[i].name, //奖品的名字,
						goods_img: awards[i].goods_img
					});
				}
				this.awardsList = awardsList;
				console.log(this.awardsList);
			},
			//发起抽奖
			playStart() {
				if (this.address_id == "") {
					this.choiceAddress = true;
					return;
				}
				ringStart({
					token: this.$token,
					address_id: this.address_id,
				}).then(res => {
					console.log(res);
					if (res.code == 1) {
						let nums = this.awardsConfig.lists.length; // 奖品总个数。
						console.log(res);
						if (res.data) {
							let index=0;
							for(let i in  this.awardsConfig.lists){
								if( this.awardsConfig.lists[i].id==res.data.id){
									index=i;
								}
							}
							this.playReward(index, nums);
						} else {
							this.playReward(nums - 1, nums);
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			playReward: function(awardIndex, nums) {
				var that = this;
				console.log(awardIndex, nums);
				//中奖index
				var awardsNum = that.awardsConfig.lists;

				// var awardIndex = 1 || Math.round(Math.random() * (awardsNum.length - 1)); //随机数
				var runNum = 4; //旋转8周
				var duration = 4000; //时长

				// 旋转角度
				that.runDeg = that.runDeg || 0;
				let preDeg = that.runDeg;
				that.runDeg = that.runDeg + (360 - (that.runDeg % 360)) + (360 * runNum - awardIndex * (360 / awardsNum.length)) +1;
				//创建动画
				console.log(that.runDeg);
				document.getElementById("zhuanpano").style ="transform:rotate("+that.runDeg+"deg);";
				that.btnDisabled = "disabled";
				setTimeout(() => {
					that.btnDisabled = "";
					if (awardIndex == nums - 1) {
						this.$message("很遗憾！未抽中任何奖品");
					} else {
						this.$message.success(
							`恭喜你！抽中了${this.awardsConfig.lists[awardIndex].name}`
						);
					}
				}, duration + 10);
				// 中奖提示
			},
			// 活动规则
			activityRule() {
				getSysconfs({
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.sysconfs = res.data;
						this.rules = this.sysconfs.lottery_rule.split(/\d\./g);
						this.rules = this.rules.filter(item => item != "");
					}
				});
			},
			// 中奖记录,获取所有的中奖记录
			getPrizeRecord(n) {
				getMyRing({ token: this.$token, page: n }).then(res => {
				  if (res.code == 1) {
				    this.prizeRecord = [...this.prizeRecord, ...res.data.data];
				    this.currentPrizeRecord = this.prizeRecord.slice(0, 5);
				    if (this.last_page != res.data.last_page) {
				      this.last_page++;
				      this.getPrizeRecord(++n);
				    }
				  }
				});
			},
			// 切换中奖记录页面。
			changePage(param) {
				if (param == "prev") {
					if (this.defalutPage == 1) return;
					else {
						this.defalutPage--;
						this.currentPrizeRecord = this.prizeRecord.slice(
							(this.defalutPage - 1) * 5,
							this.defalutPage * 5
						);
					}
				} else if (param == "next") {
					if (this.defalutPage == parseInt(this.prizeRecord.length / 5)) return;
					else {
						this.defalutPage++;
						this.currentPrizeRecord = this.prizeRecord.slice(
							(this.defalutPage - 1) * 5,
							this.defalutPage * 5
						);
					}
				} else {
					this.defalutPage = param;
					this.currentPrizeRecord = this.prizeRecord.slice(
						(this.defalutPage - 1) * 5,
						this.defalutPage * 5
					);
				}
			}
		},
		watch: {}
	};
</script>
<style lang="less" scoped>
	@import "./draw.less";
	.rule{
		width: 400px !important;
		p{
			width: 400px !important;
		}
	}
	#zhuanpano{
		transition: all 4s ease-in-out;
	}
	.addressbox {
		width: 100%;
		height: 100%;
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		z-index: 99;
	}

	.addresslist {
		width: 500px;
		height: 600px;
		background-color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99999;
		border-radius: 10px;

		.title {
			text-align: center;
			padding: 10px;
			border-bottom: 1px solid #EAEAEA;
			font-weight: 700;
		}

		.attribute {
			display: flex;
			justify-content: space-between;
			padding: 20px 10px;
			border-bottom: 1px solid #EAEAEA;
			div:nth-child(1){
				flex: 7;
				
			}
			div:nth-child(2){
				flex: 2;
				text-align: center;
			}
		}

		.addresscontent {
			overflow: auto;
			.alist{
				display: flex;
			}
			span{
				display: inline-block;
				height: 30px;
			}
			span:nth-child(1){
				
				flex: 7;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			span:nth-child(2){
		
				flex: 2;
				text-align: center;
			}
			.alist {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EAEAEA;
				
				padding: 10px;
			}

			.alist:hover {
				background-color: #EAEAEA;
			}
		}
	}
	.canvas-item-text{
		span{
			font-size: 16px;
			width: 120px;
		}
	}
</style>
