<template>
  <div class="wrap">
    <div class="nav">
      <div>积分明细</div>
    </div>
    <div class="list">
      <div class="top">
        <p class="one">日期</p>
        <p class="one">积分变化</p>
        <p class="one">详细说明</p>
      </div>
      <div v-for="(v, i) in scoreRecordList" :key="i">
        <p class="one">{{ v.time }}</p>
        <p class="one success" v-if="v.type == 1">+{{v.score}}</p>
        <p class="one reduce" v-else>{{v.score}}</p>
        <p class="one fll">{{ v.action }}</p>
      </div>
      
    </div>
  </div>
</template>
<script>
import { getScoreIndex } from "@/request/index"
export default {
  data() {
    return {
      scoreRecordList: []
    }
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
    this.start();
  },
  methods: {
    start() {
      getScoreIndex({token: this.$token}).then(res => {
        if(res.code == 1) {
			console.log(res);
          this.scoreRecordList = res.data.data;
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.list {
  min-height: 400px;
  padding-bottom: 60px;
  > div {
    height: 69px;
    line-height: 69px;
    font-size: 16px;
    color: #4d4d4d;
    display: flex;
  }
  .top {
    padding-top: 20px;
  }
  .top p {
    height: 28px;
    line-height: 28px;
    border-left: 1px solid #dbdbdb;
  }
  > div:nth-child(even) {
    background: #f8f8f8;
  }

  p {
    width: 40%;
    text-align: center;
  }
  .fll {
    text-indent: 62px;
    text-align: left;
  }
  .reduce {
    color: #FF6A21;
  }
  .success {
    color: #4EEEC8;
  }
}
.nav {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  padding-left: 32px;
  div {
    padding-left: 16px;
    padding-right: 16px;
    color: #4eeec8;
    display: inline-block;
    border-bottom: 2px solid #4eeec8;
  }
}
</style>